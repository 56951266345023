<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="7">
                    <h3>Kontakta oss så hjälper vi dig.</h3>
                    <p>
                        Om du har några frågor, behöver hjälp med ett byggprojekt eller fastighetsskötsel kontakta oss idag så hjälper vi dig ta fram en kostnadseffektiv offert.<br>
                        <br>
                        Vi på Rato AB är alltid måna om att du som kund skall kunna få hjälp så snabbt och smidigt som möjligt.<br>
                        <br>
                        Du kan antingen kontakta oss via frågeformuläret till höger, maila eller slå oss en signal så löser vi ditt problem.<br>
                        <br>
                        <b-row>
                            <b-col md="6" class="mb-4 mb-md-0">
                                Arbetsledare: Nicklas Bejhem<br>
                                <br>
                                Telefon: <a href="tel:+46734444003">+46 73 444 4003</a><br>
                                Epost: <a href="mailto:nicklas@ratoab.se">nicklas@ratoab.se</a>
                            </b-col>
                            <b-col md="6">
                                Arbetsledare: Hasse Azad<br>
                                <br>
                                Telefon: <a href="tel:+46705891111">+46 70 589 11 11</a><br>
                                Epost: <a href="mailto:hasse@ratoab.se">hasse@ratoab.se</a>
                            </b-col>
                        </b-row>
                    </p>
                </b-col>
                <b-col md="5">
                    <contact-form/>
                </b-col>
            </b-row>
        </b-container>

        <our-location class="mt-5"/>
    </div>
</template>

<script>
    import ContactForm from "../ContactForm";
    import OurLocation from "../OurLocation";
    export default {
        name: "ContactUs",
        components: {OurLocation, ContactForm}
    }
</script>

<style scoped>
</style>
