<template>
    <div>
        <hero image="https://api.ratoab.se/HeroImages?location=4"
              :dark-background="false"
              style="height: 60vh"
        />

        <contact-us class="mt-5"/>
    </div>
</template>

<script>
    import Hero from "../components/Hero";
    import ContactUs from "../components/Contact/ContactUs";
    export default {
        name: "Contact",
        components: {ContactUs, Hero},
        mounted () {
            window.scrollTo(0, 0)
        }
    }
</script>

<style scoped>

</style>
